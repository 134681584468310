import React from 'react';
import Header from '../../components/Header/index';
import { makeStyles } from '@mui/styles';
import Footer from '../../components/Footer';
import { navigate } from 'gatsby';
import { Typography, Grid, Container } from '@mui/material';
const useStyles = makeStyles(() => ({
    heading: {
        fontSize: '36px !important',
        fontWeight: '700!important',
        textAlign: 'center',
        color: '#444 !important',
        fontFamily: 'Poppins !important',
        lineHeight: '150% !important',
        paddingTop: '3rem !important',
        paddingBottom: '3rem !important'
    },
    subheading: {
        fontWeight: '400 !important',
        margin: '0 0 20px 0 !important',
        padding: '0',
        fontFamily: 'Poppins !important',
        textAlign: 'left !important',
        color: '#444 !important',
        fontSize: '30px !important',
        letterSpacing: '2px'
    },
    para: {
        lineHeight: '150% !important',
        margin: '0 0 20px 0 !important',
        padding: '0',
        fontFamily: 'Poppins !important',
        textAlign: 'left !important',
        fontSize: '17px !important',
        fontWeight: '400 !important',
        color: '#5F6368 !important'
    }
}));

const Privacy = () => {
    const classes = useStyles();
    return (
        <div>
            <Header />

            <Container>
                <Typography className={classes.heading}>Privacy Policy</Typography>
                <Grid container>
                    <Grid item md={12}>
                        <Typography className={classes.subheading}>Introduction</Typography>

                        <Typography className={classes.para}>
                            The purpose of this privacy policy (“Privacy Policy”) is to describe how{' '}
                            <b>NavaDhiti Business Consultancy Pvt Ltd</b> collects, uses and
                            maintains Information (as defined hereafter) about the Users (as defined
                            hereafter) of its website (“NavaDhiti”) and the Creators of the User
                            Content (as defined hereafter) on NavaDhiti, through NavaDhiti.
                            Capitalised terms not defined herein shall have the same meaning as
                            those ascribed to them in the Terms of Use.
                        </Typography>
                        <Typography className={classes.para}>
                            For the purposes of this Privacy Policy, Users and Creators shall
                            collectively be referred to as “you” and “your”.
                        </Typography>
                        <Typography className={classes.para}>
                            We recognize the importance of your privacy and we are committed to
                            ensuring that you are aware of how your Information is being used.
                            Please read this Privacy Policy carefully. Your use of NavaDhiti is also
                            governed by our Terms of Use available at [[terms of use]].
                        </Typography>
                        <Typography className={classes.para}>
                            If you do not understand any aspects of our Privacy Policy, please feel
                            free to contact us at contact@NavaDhiti.com. Please access/use NavaDhiti
                            only if you agree with our policies and practices encapsulated in this
                            Privacy Policy and the Terms of Use. Your use or access of NavaDhiti
                            shall constitute your agreement to the terms of this Privacy Policy.
                        </Typography>
                        <Typography className={classes.subheading}>
                            Applicability of the Policy
                        </Typography>
                        <Typography className={classes.para}>
                            This Privacy Policy shall apply to all Information, NavaDhiti Business
                            Consultancy Pvt Ltd collects on NavaDhiti including but not limited to
                            any information you may submit/upload on NavaDhiti, e-mails that you
                            exchange with NavaDhiti Business Consultancy Pvt Ltd and other Users/
                            Creators of NavaDhiti. The Privacy Policy does not apply to, nor does
                            NavaDhiti take any responsibility for, any information that is collected
                            by any third party either using NavaDhiti or through any links provided
                            on NavaDhiti or through any advertisements featured on NavaDhiti.
                        </Typography>
                        <Typography className={classes.subheading}>
                            Nature of Information Collected and Manner of Collection
                        </Typography>
                        <Typography className={classes.para}>
                            As you navigate through NavaDhiti,
                            <b>NavaDhiti Business Consultancy Pvt Ltd</b> may collect diﬀerent types
                            of information, collectively hereinafter referred to as “Information”.
                            This Information could be of the following types:
                        </Typography>
                        <Container>
                            <Typography className={classes.para}>
                                1. “Sensitive Personal Information” – information such as your
                                password;
                                <br />
                                2. “Personally Identifiable Information” – information that could be
                                used to identify you such as your name, email address, age, gender,
                                residential address and other similar information including any User
                                Information;
                                <br /> 3. “Non-Personal Information” – information that cannot be
                                used to identify you such as the webpages that you have viewed and
                                your usage patterns; <br />
                                4.“Device Information”- information which is automatically collected
                                about the operating system or version of the system from which
                                NavaDhiti is used.
                            </Typography>
                        </Container>
                        <Typography className={classes.para}>
                            NavaDhiti Business Consultancy Pvt Ltd may also collect information
                            about searches that you have carried out on NavaDhiti, advertisements or
                            third-party links that you have visited, any emails or other
                            correspondence you may have sent or exchanged with other Users or
                            Creators on NavaDhiti or with NavaDhiti Business Consultancy Pvt Ltd.
                        </Typography>
                        <Typography className={classes.para}>
                            You are responsible for ensuring the accuracy of any Information you
                            submit to NavaDhiti Business Consultancy Pvt Ltd. Inaccurate Information
                            may aﬀect your ability to use NavaDhiti, the Information you receive
                            when using NavaDhiti, and our ability to contact you. For example, your
                            email address should be kept current because that is one of the primary
                            manners in which we communicate with you.
                        </Typography>
                        <Typography className={classes.para}>
                            You understand that the Information collected by NavaDhiti Business
                            Consultancy Pvt Ltd, may be collected directly or through automatic
                            tracking of your usage of NavaDhiti through the use of cookies or other
                            tracking technologies. The data automatically collected may include
                            usage information such as details of your computing and/or communication
                            device location, connections, or any information that NavaDhiti Business
                            Consultancy Pvt Ltd may collect through the use of cookies and other
                            tracking technologies. You may decline these cookies by changing your
                            browser settings, if permitted. However, do note that this may impact
                            certain features of NavaDhiti or your experience in using NavaDhiti.
                        </Typography>
                        <Typography className={classes.para}>
                            You also may provide Information to be published or displayed or posted
                            on NavaDhiti or transmitted to other Users of NavaDhiti or third
                            parties. Any such Information that is posted or transmitted to other
                            parties will be at your own risk and will not be monitored by NavaDhiti
                            Business Consultancy Pvt Ltd for its content or compliance. Please be
                            aware that NavaDhiti Business Consultancy Pvt Ltd cannot control the
                            actions of other Users and Creators of NavaDhiti with whom you may
                            choose to share Information with.
                        </Typography>
                        <Typography className={classes.subheading}>
                            Use of your Information
                        </Typography>
                        <Typography className={classes.para}>
                            The Information that we collect on NavaDhiti will be used for the
                            following purposes:
                        </Typography>
                        <Container>
                            <Typography className={classes.para}>
                                1.Correspondence/communication with you pursuant to you registering
                                a User/ Creator account or your usage of NavaDhiti;
                                <br />
                                2.Verification of your User / Creator Account.
                                <br />
                                3.Ensuring that the content from NavaDhiti is presented in the most
                                eﬀective manner for you and for your device;
                                <br />
                                4.To provide you with newsletters, information, products or Services
                                that you request from us which we feel may interest you, where you
                                have consented to be contacted for such purposes;
                                <br />
                                5.To notify you about changes to NavaDhiti, this Privacy Policy or
                                the Terms of Use;
                                <br />
                                6.For the purpose of studying User/ Creator usage patterns;
                                <br />
                                7.For analytical purposes, including but not limited to assessing
                                usage data, usage patterns, estimate audience sizes and other
                                similar activities;
                                <br />
                                8.In any other manner we may describe when you provide the
                                Information. Except as otherwise provided herein, no Sensitive
                                Personal Information or Personally Identifiable Information will be
                                published, disclosed or shared with any third party without your
                                express consent.
                            </Typography>
                        </Container>
                        <Typography className={classes.para}>
                            Please do not include or submit any Sensitive Personal Information or
                            Personally Identifiable Information unless specifically requested by
                            NavaDhiti as part of registering a User/Creator account or pursuant to
                            other applicable processes. If NavaDhiti Business Consultancy Pvt Ltd
                            determines that any Information you have provided or uploaded violates
                            the terms of this Privacy Policy, NavaDhiti Business Consultancy Pvt Ltd
                            has the right, in its absolute discretion, to delete or destroy such
                            Information without incurring any liability to you.
                        </Typography>
                        <Typography className={classes.para}>
                            You agree to your Personally Identifiable Information being used by us
                            in order to contact you and deliver information or administrative
                            notices and any other communication relevant to your use of NavaDhiti.
                            If you do not wish to receive these communications, you may at any time
                            inform NavaDhiti using the e-mail address contact@NavaDhiti.com.
                        </Typography>
                        <Typography className={classes.subheading}>
                            Disclosure of your Information
                        </Typography>
                        <Typography className={classes.para}>
                            We consider the confidentiality and security of your Information to be
                            of the utmost importance. We will use industry standard physical,
                            technical and administrative security measures to keep your Information
                            including the Sensitive Personal Information and the Personally
                            Identifiable Information, confidential and secure and will not share it
                            with third parties, except as otherwise provided in this Privacy Policy,
                            or unless we have a good faith belief that such disclosure is necessary
                            in special cases, such as in compliance with legal/ regulatory requests,
                            to address, fraud, security or technical issues or a physical threat to
                            you or other Users or Creators. By providing any Information on
                            NavaDhiti, you consent to NavaDhiti Business Consultancy Pvt Ltd
                            providing such Information to any governmental, law enforcement or
                            regulatory authorities who exercise jurisdiction over NavaDhiti Business
                            Consultancy Pvt Ltd and NavaDhiti, upon receiving an order to do so
                            under applicable law.
                        </Typography>
                        <Typography className={classes.para}>
                            NavaDhiti Business Consultancy Pvt Ltd cannot guarantee the security of
                            such Information because the Internet is not a 100% secure environment
                            and there is some risk that an unauthorized third party may find a way
                            to circumvent our security systems or that transmission of your
                            Information over the Internet will be intercepted.
                        </Typography>
                        <Typography className={classes.para}>
                            We may disclose your Information to our agents, employees, advisors and
                            aﬃliates who require the Information for the purposes of operating and
                            maintaining NavaDhiti and for facilitating your use of the Services
                            oﬀered on NavaDhiti.
                        </Typography>
                        <Typography className={classes.subheading}>Data Retention</Typography>
                        <Typography className={classes.para}>
                            NavaDhiti Business Consultancy Pvt Ltd may retain Information, for such
                            length of time as may be required in order for NavaDhiti Business
                            Consultancy Pvt Ltd to provide the concerned User or the Creator with
                            Services, comply with legal obligations, resolve any disputes that may
                            arise, enforce its rights arising out of agreements with Users, and/or
                            for such period as may be required by legislations/orders of regulatory
                            authorities to which NavaDhiti Business Consultancy Pvt Ltd is subject.
                        </Typography>
                        <Typography className={classes.subheading}>
                            International Privacy Rights
                        </Typography>
                        <Typography className={classes.para}>
                            NavaDhiti Business Consultancy Pvt Ltds websites are operated and
                            managed on servers that may be located and operated in diﬀerent
                            jurisdictions. By using and accessing NavaDhiti, you consent to the
                            transfer to and processing of your Information including the Sensitive
                            Personal Information and the Personally Identifiable Information, on
                            servers that may be located in a jurisdiction diﬀerent from where you
                            are located, and that the protection of such Information may be diﬀerent
                            than required under the laws of your residence or location.
                        </Typography>
                        <Typography className={classes.subheading}>Data Security</Typography>
                        <Typography className={classes.para}>
                            The Information that you provide, subject to disclosure in accordance
                            with this Privacy Policy and Terms of Use, shall be maintained in a safe
                            and secure manner. NavaDhiti Business Consultancy Pvt Ltds databases and
                            Information are stored on secure servers with appropriate firewalls
                            owned by NavaDhiti Business Consultancy Pvt Ltd or by third parties.
                        </Typography>

                        <Typography className={classes.para}>
                            As a registered User or Creator (as the case may be) of NavaDhiti you
                            have the responsibility to ensure data security including that of your
                            User Information. You should use NavaDhiti in a responsible manner. You
                            are solely responsible for all acts done using your User Information.
                        </Typography>
                        <Typography className={classes.para}>
                            Given the nature of internet transactions, NavaDhiti Business
                            Consultancy Pvt Ltd does not take any responsibility for the
                            transmission of Information including the Information you provide to
                            NavaDhiti. Any transmission of your Information on the internet is done
                            at your risk. NavaDhiti Business Consultancy Pvt Ltd does not take any
                            responsibility for you or any third party circumventing the privacy
                            settings or security measures contained on NavaDhiti.
                        </Typography>
                        <Typography className={classes.para}>
                            While NavaDhiti Business Consultancy Pvt Ltd will use all reasonable
                            eﬀorts to ensure that your Information is safe and secure, it oﬀers no
                            representation, warranties or other assurances that the security
                            measures are adequate, safe, fool proof or impenetrable.
                        </Typography>
                        <Typography className={classes.subheading}>
                            Accessing and Updating your Information
                        </Typography>
                        <Typography className={classes.para}>
                            You may change, alter or otherwise modify or update your Information
                            including the details you provide for creating the User/ Creator
                            Account, at any time and contacting us at contact@NavaDhiti.com.
                        </Typography>
                        <Typography className={classes.para}>
                            Do note however, NavaDhiti Business Consultancy Pvt Ltd reserves the
                            rights to save any Non -Personal, Device Information and usage
                            Information and you are not entitled to seek the deletion of the same.
                            NavaDhiti Business Consultancy Pvt Ltd at its sole discretion may permit
                            or deny the change of any Information, if it is believed the same is
                            required to observe applicable laws.
                        </Typography>
                        <Typography className={classes.subheading}>Age Restrictions</Typography>
                        <Typography className={classes.para}>
                            NavaDhiti is only intended for Users and Creators who are competent to
                            contract under applicable law. If you are not of the requisite age, you
                            are not to provide any Personally Identifiable Information or Sensitive
                            Personal Information. If it comes to NavaDhiti Business Consultancy Pvt
                            Ltds attention that any such information pertains to an individual who
                            is not competent to contract under applicable law, such Information may
                            be deleted without notice to you.
                        </Typography>
                        <Typography className={classes.subheading}>Complaints</Typography>
                        <Typography className={classes.para}>
                            If you wish to place a complaint or raise a question/ concern with
                            regard to your privacy requirements, or otherwise view, modify, delete
                            or correct any of your Personally Identifiable Information or Sensitive
                            Personal Information, please contact our Grievance Redressal Oﬃcer, Mr.
                            Achaiah at# 1432, 6th Cross Rd, 10th Main, (Leela Palace Road), HAL 3rd
                            Stage, Kodihalli, Bengaluru - 560008, Karnataka, India, or at
                            contact@NavaDhiti.com so that we can consider and respond to your
                            request/complaint. We shall endeavor to address all requests/complaints
                            will be within thirty (30) days from the date of such a
                            request/complaint being made.
                        </Typography>
                        <Typography className={classes.subheading}>
                            Amendments to the Privacy Policy
                        </Typography>
                        <Typography className={classes.para}>
                            This Privacy Policy is subject to change at NavaDhiti Business
                            Consultancy Pvt Ltd’s sole discretion. Any changes to the Privacy Policy
                            will be notified by a notice on the home page.
                        </Typography>
                        <Typography className={classes.subheading}>Terms of Use</Typography>
                        <Typography className={classes.para}>
                            This Privacy Policy shall form a part of the Terms of Use. Copyright ©{' '}
                            <span
                                style={{ color: '#a6b41c', cursor: 'pointer' }}
                                onClick={() => {
                                    navigate('/');
                                }}
                            >
                                NavaDhiti.com{' '}
                            </span>{' '}
                            2022
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            <Footer />
        </div>
    );
};

export default Privacy;
